<template>
  <div class="database">
    <div class="searchBox">
      <el-input
        :placeholder="i18nData.placehold"
        v-model="search"
        class="input-search"
        @keyup.enter.native="toSearch"
      >
        <el-button
          class="button-search"
          slot="append"
          icon="el-icon-search"
          @click="toSearch"
        ></el-button>
      </el-input>
    </div>
    <div class="menuBox">
      <el-menu
        :default-active="databaseBar"
        class="el-menu-demo elmenu"
        mode="horizontal"
        @select="handleSelect"
        background-color="#0B6FBC"
        text-color="#dddddd"
        active-text-color="#fff"
      >
        <el-menu-item
          class="elmenuitem"
          v-for="item in tabList"
          :key="item.id"
          :index="String(item.id)"
        >
          <!-- {{locale == 'en-US'?item.diyname:item.title }} -->
          {{ item.title }}
        </el-menu-item>
      </el-menu>
    </div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>{{ i18nData.toText }}:</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: location ? '/database' : '/blogs' }">
          {{ location ? i18nData.to[0] : i18nData.to[1] }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <!-- {{
            archivesInfo.channel
              ? locale == "en-US"
                ? archivesInfo.channel.diyname
                : archivesInfo.channel.name
              : ""
          }} -->
          {{ archivesInfo.channel ? archivesInfo.channel.name : "" }}
        </el-breadcrumb-item>
        <el-breadcrumb-item>{{ archivesInfo.title }}</el-breadcrumb-item>
      </el-breadcrumb>
      <div @click="toDownload">下载</div>
    </div>
    <div ref="imageWrapper">
      <div class="detailBox" v-loading="loading">
        <div class="detailShow" :class="unfoldState ? 'unfoldBox' : 'foldBox'">
          <div class="title">
            <span>
              {{ archivesInfo.title }}
            </span>
            <span v-if="archivesInfo.title_us">
              {{ archivesInfo.title_us }}
            </span>
            <span v-if="archivesInfo.title_us_short">
              {{ archivesInfo.title_us_short }}
            </span>
          </div>
          <div class="level2title">
            <div class="level2titleItem">
              <span>英文缩写：</span>
              <span>{{ archivesInfo.cas || "--" }}</span>
            </div>
            <div class="level2titleItem">
              <span>分子式：</span>
              <span>{{ archivesInfo.formula || "--" }}</span>
            </div>
            <div class="level2titleItem">
              <span>药剂学类别：</span>
              <span>{{ archivesInfo.category || "--" }}</span>
            </div>
          </div>
          <div class="content" v-html="archivesInfo.content">
            <!-- <span>
            Beautiful Soup 3 only works on Python 2.x, but Beautiful Soup 4 also
            works on Python 3.x. Beautiful Soup 4 is faster, has more features,
            and works with third-party parsers like lxml and html5lib. You
            should use Beautiful Soup 4 for all new projects, and you should
            port any existing projects to Beautiful Soup 4.
          </span>
          <img src="./../../public/img/detail/detail_img1.png" alt="" />
          <span>
            Beautiful Soup 3 only works on Python 2.x, but Beautiful Soup 4 also
            works on Python 3.x. Beautiful Soup 4 is faster, has more features,
            and works with third-party parsers like lxml and html5lib. You
            should use Beautiful Soup 4 for all new projects, and you should
            port any existing projects to Beautiful Soup 4.
          </span> -->
          </div>
        </div>
        <div class="unfold" v-if="!unfoldState" @click="toUnfold">
          <span><i class="el-icon-d-arrow-right"></i></span>
          <span>{{ i18nData.readmore }}</span>
        </div>
        <el-divider></el-divider>
        <div class="comment" v-if="false">
          <div v-if="!(commentList.length > 0)">
            {{ i18nData.nocomment }}
          </div>
          <div class="textareaBox">
            <i class="el-icon-edit-outline"></i>
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              :placeholder="i18nData.commenttip"
              v-model="content"
            >
            </el-input>
          </div>
          <div class="btn">
            <template v-if="LoggedIn">
              <div class="btnL" v-if="archivesInfo.price > 0">
                <el-button
                  type="primary"
                  size="small"
                  plain
                  @click="showModelFn"
                  >{{ i18nData.applybtn }}</el-button
                >
              </div>
              <div class="btnR">
                <el-button type="primary" size="small" plain @click="submit">{{
                  i18nData.comment
                }}</el-button>
              </div>
            </template>
            <template v-else>
              <div class="btnR">
                <div>{{ i18nData.buttontip }}</div>
                <el-button type="primary" size="small" plain @click="toLogin">{{
                  i18nData.button
                }}</el-button>
              </div>
            </template>
          </div>
        </div>
        <div class="commentBox" v-if="commentList.length > 0">
          <div class="title">{{ i18nData.commentlist }}</div>
          <div class="commentList">
            <!-- <div class="commentItem">
            <div class="avatar">
              <img src="./../../public/img/detail/avatar_default.png" alt="" />
            </div>
            <div class="content">
              <span class="name">Mr Wu</span>
              <span class="comment"
                >Science is really great, and the biological world is
                amazing.</span
              >
            </div>
          </div> -->
            <div
              class="commentItem"
              v-for="(item, index) in commentList"
              :key="index"
            >
              <div class="avatar">
                <img :src="item.user && item.user.avatar" alt="" />
              </div>
              <div class="content">
                <div class="nameDate">
                  <span class="name">{{
                    item.user && item.user.nickname
                  }}</span>
                  <span>{{ item.create_date }}</span>
                </div>
                <span class="comment" v-html="item.content"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="otherBox" v-if="unfoldState">
        <div class="data">{{ archivesInfo.create_date }}</div>
        <div class="other">
          <!-- <div>
            <i class="el-icon-chat-dot-square"></i
            ><span>{{ archivesInfo.comments }}</span>
          </div> -->
          <div>
            <i class="el-icon-view"></i><span>{{ archivesInfo.views }}</span>
          </div>

          <template v-if="collectStatus">
            <div class="chooseColor collection" @click="delCollection('del')">
              <i class="el-icon-star-on"></i>
              <span>Collected</span>
            </div>
          </template>
          <template v-else>
            <div
              class="collection"
              @click="LoggedIn ? addCollection() : toLogin()"
            >
              <i class="el-icon-star-off"></i>
              <span>Collect</span>
            </div>
          </template>
        </div>
      </div>
      <div class="applyModel" v-show="showModel">
        <el-card class="box-card applyBox" :body-style="{ padding: '20px' }">
          <div class="close">
            <i class="el-icon-close" @click="showModelFn(1)"></i>
          </div>
          <div class="content">
            {{ i18nData.npopup.content }}
          </div>
          <div class="btnBox">
            <el-button type="primary" size="small" plain @click="showPhone">{{
              i18nData.npopup.button1
            }}</el-button>
            <el-button type="primary" size="small" plain @click="toApply">{{
              i18nData.npopup.button2
            }}</el-button>
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import { toTop, search } from "./../common/top.mixin.js";
import html2canvas from "html2canvas";
import JsPDF from "jspdf";

export default {
  name: "detail",
  mixins: [toTop, search],
  components: {},
  data() {
    return {
      id: 0,
      scale: 2,
      loading: true,
      imgUrl: "",
      // 选中分类
      databaseBar: "",
      archivesInfo: {},
      channelInfo: {},
      commentList: [],
      imagesList: [],
      downloadurl: {},
      isRender: false,
      activeIndex2: "1",
      // 评论
      content: "",
      pid: 0,
      unfoldState: true,
      // 收藏状态
      collectStatus: false,
      // 是否登录
      LoggedIn: false,
      // 弹窗
      showModel: false,
      tabList: [
        {
          id: 24,
          title: "其他",
          seotitle: "Other",
          items: 0,
          image: "https://admin.pku-epquangeno.com/assets/addons/cms/img/noimage.jpg",
          archivesList: []
        },
        {
          id: 6,
          title: "多环芳烃",
          seotitle: "PAHs",
          items: 8,
          image:
            "https://admin.pku-epquangeno.com/uploads/20220728/592d14cfeaa2ea69c46088786ecf14ee.png",
          archivesList: []
        },
        {
          id: 5,
          title: "药物及个人护理品",
          seotitle: "PPCPs",
          items: 7,
          image:
            "https://admin.pku-epquangeno.com/uploads/20220728/fdc291ead730e1513b3f8f3cc79f5f26.png",
          archivesList: []
        },
        {
          id: 4,
          title: "全氟辛酸类",
          seotitle: "PFASs",
          items: 5,
          image:
            "https://admin.pku-epquangeno.com/uploads/20220728/a05dc8ab6dadc5ec62d07ac1132962a6.png",
          archivesList: []
        },
        {
          id: 3,
          title: "挥发性有机污染物",
          seotitle: "VOCs",
          items: 5,
          image:
            "https://admin.pku-epquangeno.com/uploads/20220728/69eeaeaff3c15781c8536d600e81d2ba.png",
          archivesList: []
        }
      ]
    };
  },
  computed: {
    isShow() {
      return this.archivesInfo.images != "";
    },
    // 文章当前位置
    location() {
      console.log(this.archivesInfo.channel);
      let flag = false;
      if (this.archivesInfo.channel) {
        let tabList = this.tabList;
        console.log(tabList);
        flag = tabList.some(item => {
          return item.id == this.archivesInfo.channel.id;
        });
      }
      console.log(flag);
      return flag;
    }
  },
  created() {
    this.$store.state.activeIndex = this.$route.path;
    this.id = this.$route.query.id;
    this.getCategory();
    this.getArchivesDetail();
    this.backTop();
    console.log(this.$route.query.id);
  },
  mounted() {
    if (this.$store.state.vuex_token) {
      this.LoggedIn = true;
      this.isCollection();
    }
  },
  methods: {
    isSplit(nodes, index, pageHeight) {
      // 计算当前这块dom是否跨越了a4大小，以此分割
      if (
        nodes[index].offsetTop + nodes[index].offsetHeight < pageHeight &&
        nodes[index + 1] &&
        nodes[index + 1].offsetTop + nodes[index + 1].offsetHeight > pageHeight
      ) {
        return true;
      }
      return false;
    },
    uploadPdf() {
      // let ST = document.documentElement.scrollTop || document.body.scrollTop;
      // let SL = document.documentElement.scrollLeft || document.body.scrollLeft;
      // document.documentElement.scrollTop = 0;
      // document.documentElement.scrollLeft = 0;
      // document.body.scrollTop = 0;
      // document.body.scrollLeft = 0;
      //获取滚动条的位置并赋值为0，因为是el-dialog弹框，并且内容较多出现了纵向的滚动条,截图出来的效果只能截取到视图窗口显示的部分,超出窗口部分则无法生成。所以先将滚动条置顶
      const A4_WIDTH = 592.28;
      const A4_HEIGHT = 841.89;
      let imageWrapper = this.$refs.imageWrapper; // 获取DOM
      let pageHeight = (imageWrapper.offsetWidth / A4_WIDTH) * A4_HEIGHT;
      let lableListID = imageWrapper.querySelectorAll("p");
      // 进行分割操作，当dom内容已超出a4的高度，则将该dom前插入一个空dom，把他挤下去，分割
      for (let i = 0; i < lableListID.length; i++) {
        let multiple = Math.ceil(
          (lableListID[i].offsetTop + lableListID[i].offsetHeight) / pageHeight
        );
        if (this.isSplit(lableListID, i, multiple * pageHeight)) {
          let divParent = lableListID[i].parentNode; // 获取该div的父节点
          let newNode = document.createElement("div");
          newNode.className = "emptyDiv";
          newNode.style.background = "#ffffff";
          let _H =
            multiple * pageHeight -
            (lableListID[i].offsetTop + lableListID[i].offsetHeight);
          //留白
          newNode.style.height = _H + 30 + "px";
          newNode.style.width = "100%";
          let next = lableListID[i].nextSibling; // 获取div的下一个兄弟节点
          // 判断兄弟节点是否存在
          if (next) {
            // 存在则将新节点插入到div的下一个兄弟节点之前，即div之后
            divParent.insertBefore(newNode, next);
          } else {
            // 不存在则直接添加到最后,appendChild默认添加到divParent的最后
            divParent.appendChild(newNode);
          }
        }
      }
      //接下来开始截图
      this.$nextTick(() => {
        html2canvas(imageWrapper, {
          allowTaint: true,
          x: imageWrapper.getBoundingClientRect().left + 13, // 绘制的dom元素相对于视口的位置
          y: imageWrapper.getBoundingClientRect().top,
          width: imageWrapper.offsetWidth - 15, // 因为多出的需要剪裁掉，
          height: imageWrapper.offsetHeight,
          backgroundColor: "#FFF", //一定要设置北京颜色，否则有的浏览器就会变花~，比如Edge
          useCORS: true,
          scale: 3, // 图片模糊
          dpi: 350 //z
        }).then(canvas => {
          let pdf = new JsPDF("p", "mm", "a4"); //A4纸，纵向
          let ctx = canvas.getContext("2d"),
            a4w = 190,
            a4h = 277, //A4大小，210mm x 297mm，四边各保留10mm的边距，显示区域190x277
            imgHeight = Math.floor((a4h * canvas.width) / a4w), //按A4显示比例换算一页图像的像素高度
            renderedHeight = 0;

          while (renderedHeight < canvas.height) {
            let page = document.createElement("canvas");
            page.width = canvas.width;
            page.height = Math.min(imgHeight, canvas.height - renderedHeight); //可能内容不足一页
            //用getImageData剪裁指定区域，并画到前面创建的canvas对象中
            page
              .getContext("2d")
              .putImageData(
                ctx.getImageData(
                  0,
                  renderedHeight,
                  canvas.width,
                  Math.min(imgHeight, canvas.height - renderedHeight)
                ),
                0,
                0
              );
            pdf.addImage(
              page.toDataURL("image/jpeg", 0.2),
              "JPEG",
              10,
              10,
              a4w,
              Math.min(a4h, (a4w * page.height) / page.width)
            ); //添加图像到页面，保留10mm边距
            renderedHeight += imgHeight;
            if (renderedHeight < canvas.height) pdf.addPage(); //如果后面还有内容，添加一个空页
          }
          pdf.save(this.archivesInfo.title + "详情.pdf"); //此处命名可由自己对应项目而写 || 根据自己简单配置那当前页面标题传过来进行命名
        });
      });
    },
    toDownload() {
      const trs = this.$refs.imageWrapper.querySelectorAll("p");
      html2canvas(this.$refs.imageWrapper, {
        backgroundColor: "#FFFFFF", //一定要设置白颜色，否则有的浏览器就会变花~，比如Edge
        scale: this.scale,
        useCORS: true,
        allowTaint: true
      }).then(canvas => {
        // let dataURL = canvas.toDataURL("image/png");
        // this.imgUrl = dataURL;
        // var a = document.createElement("a"); // 生成一个a元素
        // var event = new MouseEvent("click"); // 创建一个单击事件
        // a.download = name || "photo"; // 设置图片名称
        // a.href = dataURL; // 将生成的URL设置为a.href属性
        // a.dispatchEvent(event); // 触发a的单击事件
        var contentWidth = canvas.width;
        var contentHeight = canvas.height;
        // 一页pdf显示html页面生成的canvas高度;
        var pageHeight = (contentWidth / 592.28) * 841.89;
        // 未生成pdf的html页面高度
        var leftHeight = contentHeight;
        // 页面偏移
        var position = 0;
        // a4纸的尺寸[595.28,841.89]，html页面生成的canvas在pdf中图片的宽高
        var imgWidth = 595.28;
        var imgHeight = (592.28 / contentWidth) * contentHeight;
        var pageData = canvas.toDataURL("image/jpeg", 1.0);
        var pdf = new JsPDF("", "pt", "a4");
        // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度(841.89)
        // 当内容未超过pdf一页显示的范围，无需进行分页
        if (leftHeight < pageHeight) {
          pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
        } else {
          // 当内容超过pdf一页显示的高度范围时，进行分页
          while (leftHeight > 0) {
            pdf.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
            leftHeight -= pageHeight;
            position -= 841.89;
            // 避免添加空白页
            if (leftHeight > 0) {
              pdf.addPage();
            }
          }
        }
        // const contentWidth = canvas.width;
        // const contentHeight = canvas.height;
        // console.log(canvas);
        // //    console.log("contentWidth",contentWidth)  = 2倍的页面元素宽高
        // //a4纸的尺寸pt [595.28,841.89]
        // const a4Width = 595.28;
        // const a4Height = 841.89;
        // //一页pdf能显示多大的canvas元素
        // var pageHeight = (contentWidth / a4Width) * a4Height; // 单位px
        // console.log("pageHeight", pageHeight);
        // //未生成pdf的html页面高度
        // let leftHeight = contentHeight;
        // //针对单个页面偏移
        // let position = 0;
        // //针对整个图片的偏移
        // let hasPos = 0;
        // //html页面生成的canvas在pdf中图片的宽高
        // const imgWidth = a4Width;
        // const imgHeight = (a4Width / contentWidth) * contentHeight; //单位pt
        // let pad = 60; //a4纸上间距60
        // const pad2 = 20; //a4纸下间距20， 为了分页截取的图片有上边距，下面有个15的偏移量
        // const empty = 60; //表格无数据 nodata高度60

        // let dom2a4 = h => {
        //   // px转pt
        //   return (h * this.scale) / (pageHeight / a4Height);
        // };
        // let pt2canvasH = h => {
        //   //pt转px
        //   return (contentWidth / a4Width) * h;
        // };
        // const topFixedImgHeight = dom2a4(28); //页面上方固定的高
        // const botFixedImgHeight = dom2a4(45); //页面下方固定的高
        // const pageData = canvas.toDataURL("image/jpeg", 1.0);
        // let pdf = new JsPDF("p", "pt", "a4");

        // //分页
        // if (leftHeight < pageHeight) {
        //   console.log(1);
        //   pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
        //   console.log("addImage");
        // } else {
        //   console.log(2);
        //   let nowH = topFixedImgHeight;
        //   for (let i = 0; i < trs.length; i++) {
        //     let _h = trs[i].offsetHeight;
        //     //当前tr和下一个tr都是空样式--代表此表格是空， 或者当前tr是空，没有下一个tr了，也代表此表格是空
        //     if (
        //       trs[i].className === "" &&
        //       ((trs[i + 1] && trs[i + 1].className === "") ||
        //         i === trs.length - 1)
        //     ) {
        //       _h = _h + empty;
        //     }

        //     if (nowH + dom2a4(_h) < a4Height - dom2a4(pad + pad2)) {
        //       nowH += dom2a4(_h);
        //       if (i === trs.length - 1) {
        //         if (nowH + botFixedImgHeight > a4Height - dom2a4(pad + pad2)) {
        //           let clipImgData = this.cropCanvas(
        //             canvas,
        //             contentWidth,
        //             contentHeight,
        //             pt2canvasH(nowH + dom2a4(pad)), //针对整个dom生成的canvas 需要截取的高度
        //             pt2canvasH(hasPos) //截取的起始纵坐标位置
        //           );
        //           pdf.addImage(clipImgData, "JPEG", 0, 15, imgWidth, imgHeight);
        //           pdf.addPage();
        //           position = nowH + dom2a4(pad);
        //           hasPos += position;
        //           pdf.addImage(
        //             pageData,
        //             "JPEG",
        //             0,
        //             -hasPos,
        //             imgWidth,
        //             imgHeight
        //           );
        //         } else {
        //           let clipImgData = this.cropCanvas(
        //             canvas,
        //             contentWidth,
        //             contentHeight,
        //             pt2canvasH(nowH + dom2a4(pad) + botFixedImgHeight), //针对整个dom生成的canvas 需要截取的高度
        //             pt2canvasH(hasPos) //截取的起始纵坐标位置
        //           );
        //           pdf.addImage(clipImgData, "JPEG", 0, 15, imgWidth, imgHeight);
        //           console.log("addImage");
        //         }
        //       }
        //     } else {
        //       position = nowH + dom2a4(pad);
        //       let clipImgData = this.cropCanvas(
        //         canvas,
        //         contentWidth,
        //         contentHeight,
        //         pt2canvasH(nowH + dom2a4(pad)), //针对整个dom生成的canvas 需要截取的高度
        //         pt2canvasH(hasPos) //截取的起始纵坐标位置
        //       );
        //       pdf.addImage(clipImgData, "JPEG", 0, 15, imgWidth, imgHeight);
        //       console.log("addImage");
        //       hasPos += position;
        //       nowH = dom2a4(_h);
        //       console.log(3);
        //       pdf.addPage();
        //       pad = 0;
        //       console.log("addPage");
        //       if (i === trs.length - 1) {
        //         pdf.addImage(pageData, "JPEG", 0, -hasPos, imgWidth, imgHeight);
        //       }
        //     }
        //   }
        // }
        pdf.save(this.archivesInfo.title + "详情.pdf"); //此处命名可由自己对应项目而写 || 根据自己简单配置那当前页面标题传过来进行命名
      });
    },
    cropCanvas(canvas, contentWidth, contentHeight, positionTop, hasPos) {
      var ctx = canvas.getContext("2d");
      // 新canvas控件- 保存裁剪后的图片
      var newCanvas = document.createElement("canvas");
      var newCtx = newCanvas.getContext("2d");
      newCanvas.setAttribute("width", contentWidth);
      newCanvas.setAttribute("height", contentHeight);
      //导出的pdf默认黑色背景，需要用白色填充
      newCtx.fillStyle = "#FFFFFF";
      newCtx.fillRect(0, 0, contentWidth, contentHeight);
      //hasPos-3 防止截取掉表格title的最上方1px
      var imgRgbData = ctx.getImageData(
        0,
        hasPos > 0 ? hasPos - 3 : hasPos,
        contentWidth,
        positionTop
      );
      console.log("positionTop=", positionTop, " hasPos=", hasPos);
      // 把裁剪后的像素数据渲染到新canvas
      newCtx.putImageData(imgRgbData, 0, 0);
      // 获取裁剪后图片的base64数据
      var imgBase64 = newCanvas.toDataURL("image/jpeg", 1.0);
      return imgBase64;
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
      this.databaseBar = key;
      this.$router.push({
        path: "/database",
        query: {
          databaseBar: this.databaseBar
        }
      });
    },
    toUnfold() {
      this.unfoldState = true;
    },
    toLogin() {
      this.$message({
        message: "前往登录页面",
        type: "success"
      });
      setTimeout(() => {
        this.$router.push({ path: "/login" });
      }, 1200);
    },
    toApply() {
      this.$router.push({ path: "/my/mySubmit" });
    },
    async getArchivesDetail() {
      let res = await this.$api.https.getArchivesDetail({
        id: this.id
      });
      if (!res.code) {
        return;
      }
      this.loading = false;
      this.archivesInfo = res.data.archivesInfo || {};
      this.channelInfo = res.data.channelInfo || [];
      this.commentList = res.data.commentList || [];
      this.downloadurl = res.data.archivesInfo.downloadurl || {};
      this.imagesList = res.data.archivesInfo.images.split(",");
      this.isRender = true;
      this.$store.state.vuex__token__ = res.data.__token__;
    },
    // 获取分类
    async getCategory() {
      // 这里用try catch包裹，请求失败的时候就执行catch里的
      try {
        let params = {
          channel: "",
          menu_index: ""
        };
        let res = await this.$api.https.getCategory(params);
        console.log(res);
        if (!res.code) {
          this.$message({
            message: res.msg
          });
          return;
        }
        if (res.code == 1) {
          this.tabList = res.data.list;
        } else {
          this.$message({
            message: res.msg
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    // 判断是否收藏isCollection
    async isCollection() {
      let parms = {
        aid: this.id,
        type: "archives"
      };
      let res = await this.$api.https.isCollection(parms);
      if (res.code) {
        this.collectStatus = true;
      } else {
        this.collectStatus = false;
      }
    },
    // 收藏
    async addCollection() {
      let parms = {
        aid: this.id,
        type: "archives"
      };
      let res = await this.$api.https.addCollection(parms);
      if (res.code) {
        this.$message({
          message: res.msg,
          type: "success"
        });
        this.collectStatus = true;
      } else {
        this.$message({
          message: res.msg,
          type: "error"
        });
      }
      if (res.msg == "请勿重复收藏") {
        this.collectStatus = true;
      }
    },
    // 取消收藏
    async delCollection(flag) {
      try {
        let params = {
          aid: this.id,
          type: "archives"
        };
        let res = await this.$api.https.delsCollection(params);
        console.log(res);
        if (res.code) {
          this.$message({
            message: res.msg,
            type: "success"
          });
          this.collectStatus = false;
        } else {
          this.$message({
            message: res.msg,
            type: "error"
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    // 提交评论
    async submit() {
      if (!this.content) {
        this.$message({
          message: "请输入评论内容！",
          type: "error"
        });
        return;
      }
      try {
        let params = {
          content: this.content,
          aid: this.id,
          pid: this.pid //回复的用户上一条ID
        };
        let res = await this.$api.https.goCommentPost(params);
        console.log(res);
        if (res.code == 1) {
          this.$message({
            message: res.msg,
            type: "success"
          });
          this.content = "";
          if (res.data && res.data.comment) {
            this.commentList = [res.data.comment, ...this.commentList];
          }
        } else {
          this.$message({
            message: res.msg,
            type: "error"
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    // 弹窗开关
    showModelFn(flag) {
      if (flag == 1) {
        this.showModel = false;
        return;
      }
      this.showModel = true;
    },
    // 展示电话号码
    showPhone() {
      console.log(this.$store.state.vuex_config);
      let phone = this.$store.state.vuex_config.config.phone_number;
      this.$confirm("Please contact customer service", phone, {
        cancelButtonText: "Close",
        showConfirmButton: false,
        showClose: false,
        iconClass: "el-icon-phone",
        cancelButtonClass: "closeConfirm",
        center: true
      });
    }
  }
};
</script>
<style lang="scss" scoped>
$vuex_color: #0495f3;
::v-deep .el-button {
  color: #282828 !important;
}
@mixin btnStyle($width, $size) {
  .el-button--primary.is-plain {
    color: $vuex_color;
    background: #ffffff;
    border-color: $vuex_color;
    width: $width;
    font-size: $size;
    transition: 0.3s;
  }
  .el-button--primary.is-plain:focus,
  .el-button--primary.is-plain:hover {
    color: #ffffff;
    background: $vuex_color;
    border-color: $vuex_color;
  }
}
.database {
  background-color: #ffffff;
  line-height: 24px;
  text-align: left;
  .searchBox {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d2d2d2;
    .input-search {
      width: 487px;
      height: 30px;
      border-radius: 5px;
    }
    .button-search {
      background-color: #fff;
    }
  }
  .menuBox {
    .elmenu {
      display: flex;
      justify-content: space-between;
      ::v-deep .el-menu-item {
        flex: 1;
        text-align: center;
      }
    }
    ::v-deep .el-menu--horizontal > .el-menu-item.is-active {
      font-weight: bold;
    }
  }
  .breadcrumb {
    padding: 10px 50px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      font-size: 14px;
      color: $vuex_color;
      cursor: pointer;
    }
  }
  .detailBox {
    display: flex;
    flex-direction: column;
    padding: 0 50px;
    font-size: 14px;
    .detailShow {
      .title {
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin: 30px 0;
        span {
          margin: 0 5px;
        }
      }
      .level2title {
        display: flex;
        justify-content: space-between;
        min-width: 30%;
        flex-wrap: wrap;
      }
      .content {
        margin-top: 20px;
        min-height: 50vh;
        line-height: 28px;
        ::v-deep img {
          width: 100%;
          margin: 20px 0;
        }
      }
    }
    .foldBox {
      max-height: 60vh;
      overflow: hidden;
    }
    .unfoldBox {
    }
    .unfold {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 30px 0 0;
      cursor: pointer;
      span {
        text-align: center;
      }
      span:nth-child(1) {
        font-size: 20px;
        i {
          transform: rotate(90deg);
        }
      }
      span:nth-child(2) {
        font-size: 16px;
        color: #2498e9;
      }
    }
    .comment {
      display: flex;
      flex-direction: column;
      .textareaBox {
        padding: 10px 0 0 20px;
        display: flex;
        background-color: #e4e8ef;
        border-radius: 5px;
        i {
          padding: 10px 0 0;
          font-size: 20px;
        }
        ::v-deep.el-textarea__inner {
          border: none;
          padding: 10px 20px 20px 10px;
          background-color: transparent;
        }
        ::placeholder {
          color: #282828;
        }
      }
      .btn {
        padding: 10px 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .btnL,
        .btnR {
          display: flex;
          align-items: center;
          div {
            font-size: 12px;
            margin-right: 20px;
            color: #838383;
          }
          @include btnStyle(120px, 12px);
        }
        .btnL {
          flex: 1;
        }
      }
    }
    .commentBox {
      display: flex;
      flex-direction: column;
      .title {
        color: #838383;
        font-size: 12px;
        padding: 10px 0;
      }
      .commentList {
        display: flex;
        flex-direction: column;
        .commentItem {
          display: flex;
          margin-bottom: 10px;
          .avatar {
            width: 40px;
            height: 40px;
            margin: 0 10px 10px 10px;
            img {
              width: 100%;
              height: 100%;
              border-radius: 5px;
            }
          }
          .content {
            flex: 1;
            display: flex;
            flex-direction: column;
            font-size: 12px;
            .nameDate {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .name {
                color: #a1a1a1;
              }
            }
            .comment {
              display: block;
            }
          }
        }
      }
    }
  }
  .otherBox {
    padding: 0 50px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #838383;
    .data {
    }
    .other {
      display: flex;
      div {
        margin-left: 30px;
        display: flex;
        align-items: center;
        i {
          margin-right: 10px;
          font-size: 20px;
        }
      }
      .collection {
        cursor: pointer;
      }
      .chooseColor {
        color: $vuex_color;
      }
    }
  }
}
.applyModel {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  .applyBox {
    width: 300px;
    box-shadow: 2px 2px 20px 0 rgb(0 0 0 / 36%);
    .close {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      padding: 0 0 10px;
      i {
        font-size: 20px;
        margin: -10px -10px 0 0;
      }
    }
    .content {
      font-size: 14px;
    }
    .btnBox {
      padding: 20px 0 0;
      display: flex;
      justify-content: space-around;
      align-items: center;
      @include btnStyle(100px, 16px);
    }
  }
}
</style>
